<template>
    <div class="gg-container">
        <div class="content">
            <div class="content-top">
                基础信息
            </div>
            <div class="row input" style="margin:50px 0">
                <div style="width:150px">
                    <span style="color:red">*</span>班次名称：
                </div>
                <el-input v-model="title" placeholder="请输入内容"></el-input>
            </div>
            <div class="row" style="align-items: center;">
                <div class="content-top">
                    上下班时间
                </div>
                <div
                        style="color:#25aed8;font-size:19px;margin-left:20px"
                        @click="handleAdd"
                >
                    添加
                </div>
            </div>
            <div class="create">
                <div v-for="(item, index) in list" :key="index" class="add-content">
                    <div class="row" style="justify-content: space-between;">
                        <div>
                            <span style="color:red">*</span>第{{ index + 1 }}次上班时间
                        </div>
                        <i
                                class="el-icon-circle-close"
                                style="font-size:20px"
                                @click="handleDel(index)"
                        ></i>
                    </div>
                    <div class="row" style="margin:20px 0;">
                        <div style="margin-right:20px;font-weight: bold;">上班时间</div>
                        <el-time-select
                                @change="selectDateUp($event, index)"
                                v-model="item.start_at"
                                :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00',
              }"
                                placeholder="任意时间点"
                        >
                        </el-time-select>
                    </div>
                    <div class="row">
                        <div style="margin-right:20px;font-weight: bold;">下班时间</div>
                        <el-time-select
                                :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00',
              }"
                                @change="selectDateDown($event, index)"
                                v-model="item.end_at"
                                placeholder="任意时间点"
                        >
                        </el-time-select>
                    </div>
                </div>
            </div>
            <div class="footer">
                <el-button type="info" style="margin-rihgt:20px;" @click="closeDrawer">取消</el-button>
                <el-button type="primary" @click="handleSave">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { postSchedus, putSchedus } from "@/api/rowWorks/index";
export default {
    name: "elDrawer",
    props: {
        drawerVisible: {
            type: Boolean,
            default: false,
        },
        info: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            list: [], //班次列表
            title: "", //班次名称
        };
    },
    created() {
        console.log(this.info);
        if (this.info.edit == "edit") {
            this.list = this.info.list;
            this.title = this.info.title;
        }
    },
    methods: {
        //选择上班时间
        selectDateUp(event, index) {
            console.log(event, index);
            //将e的时间转换为时分秒
        },
        //选择下班时间
        selectDateDown(event, index) {
            console.log(event, index);
            //将时间转换为时分秒
        },
        //保存
        async handleSave() {
            let list = this.list;
            list.forEach((item) => {
                if (item.end_at == "24:00") {
                    item.end_at = "23:59";
                }
                if(item.start_at == "24:00"){
                    item.start_at = "23:59";
                }
            });
            if (this.info.edit == "edit") {
                let data = {
                    title: this.title,
                    list: this.list,
                };
                let id = this.info.id;
                let res = await putSchedus(data, id);
                if (res.code == 200) {
                    this.$message.success("编辑成功");
                    this.closeDrawer();
                    //刷新数据
                    this.$emit("getList");
                } else {
                    this.$message.error(res.msg);
                }
            } else {
                let data = {
                    title: this.title,
                    list: list,
                };
                let res = await postSchedus(data);
                if (res.code == 200) {
                    this.$message.success("保存成功");
                    this.closeDrawer();
                    //刷新数据
                    this.$emit("getList");
                } else {
                    this.$message.error(res.msg);
                }
            }
            console.log(list);
        },
        //删除
        handleDel(e) {
            let index = e;
            console.log(index);
            //删除
            this.list.splice(index, 1);
        },
        closeDrawer() {
            this.$emit("closeDrawer", false);
        },
        //增加一个
        handleAdd() {
            console.log("增加一个");
            this.list.push({
                start_at: "",
                end_at: "",
                id: 0,
            });
            console.log(this.list);
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .content-top {
    font-size: 24px;
    padding-bottom: 6px;
    font-weight: bold;
    width: 100rpx !important;
    border-bottom: 3px solid #25aed8;
  }
}
.row {
  display: flex;
}
.create {
  display: flex;
  flex-direction: column;
}
.add-content {
  width: 500px;
  padding: 12px !important;
  box-sizing: border-box;
  border: 1px solid #bebdbd;
  border-radius: 8px;
  margin-top: 20px;
}
.input {
 ::v-deep .el-input__inner {
    height: 30px;
    width: 250px;
  }
}
.footer {
  position: absolute;
  right: 100px;
  bottom: 200px;
}
</style>